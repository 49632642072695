import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedDate } from "react-intl"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import slugify from "react-slugify"

import Layout from "../layouts"

import LocalizedLink from "../components/LocalizedLink"
import PlaceholderCookieConsentReset from "../components/PlaceholderCookieConsentReset"
import PlaceholderEmailAddress from "../components/PlaceholderEmailAddress"
import PlaceholderPostalAddress from "../components/PlaceholderPostalAddress"

import { smartReplacements, hashString } from "../utils/text-properties"

const BlogPage = props => {
  const {
    pageContext: { locale },
    data: {
      allContentfulPage: { nodes: pages },
      allContentfulBlogPost: { nodes: posts },
    },
  } = props

  /**
   * get localized page
   * @param {Array} pages
   * @param {String} locale
   * @returns
   */
  const getLocalizedPage = (pages, locale) => {
    return pages.find(page => page.node_locale === locale)
  }

  const localizedPage = getLocalizedPage(pages, locale)

  /**
   * get localized posts with smart replacements
   * @param {Array} posts
   * @param {String} locale
   * @returns
   */
  const getLocalizedPosts = (posts, locale) => {
    return posts
      .map(post => ({
        ...post,
        postTitle: smartReplacements(post.postTitle, locale)
      }))
      .filter(post => post.node_locale === locale)
  }

  const localizedPosts = getLocalizedPosts(posts, locale)

  // see: https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
  // and: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
  /**
   * The `renderNode` keys should be one of the following `BLOCKS` and `INLINES` properties:
   *    - BLOCKS
   *      - DOCUMENT
   *      - PARAGRAPH
   *      - HEADING_1 ... HEADING_6
   *      - UL_LIST, OL_LIST, LIST_ITEM
   *      - QUOTE
   *      - HR
   *      - EMBEDDED_ENTRY, EMBEDDED_ASSET
   *    - INLINES
   *      - EMBEDDED_ENTRY
   *      - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK
   *
   * The `renderMark` keys should be one of the following `MARKS` properties:
   *    - BOLD, ITALIC, UNDERLINE, CODE
   *
   * The `renderText` callback is a function that has a single string argument and returns a
   * React node. Each text node is evaluated individually by this callback. A possible use case
   * for this is to replace instances of `\n` produced by `Shift + Enter` with `<br />` React
   * elements.
   *
   * The `scrollMarginTop` property ensures that the headline is in the field of view.
   * More details: https://gomakethings.com/how-to-prevent-anchor-links-from-scrolling-behind-a-sticky-header-with-one-line-of-css/
   */

  /**
   * inject an anchor using `slugify` into each heading
   * @param {String} node
   * @returns Object
   */
  const injectHeaderAnchor = node => {
    const slug = slugify(node.content[0].value)
    const CustomHeading = `h${node.nodeType.substr(-1)}`
    return (
      <CustomHeading id={slug} style={{ scrollMarginTop: "6em" }}>
        {node.content[0].value}
      </CustomHeading>
    )
  }

  const renderOptions = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: node => {
        return (
          <LocalizedLink to={`/${node.data.target.pageSlug}`}>
            {node.content[0].value}
          </LocalizedLink>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        if (node.data.target.component === "Cookie Consent Reset") {
          return <PlaceholderCookieConsentReset />
        }
        if (node.data.target.component === "Email Address") {
          return <PlaceholderEmailAddress />
        }
        if (node.data.target.component === "Postal Address") {
          return <PlaceholderPostalAddress />
        }
        return <React.Fragment></React.Fragment>
      },
      [BLOCKS.HEADING_1]: injectHeaderAnchor,
      [BLOCKS.HEADING_2]: injectHeaderAnchor,
      [BLOCKS.HEADING_3]: injectHeaderAnchor,
    },
    renderText: text => {
      return smartReplacements(text, locale)
        .split("\n")
        .reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
  }

  return (
    <Layout title={localizedPage.pageTitle} description={""}>
      <section className="section has-background-paper">
        <div className="container">
          <h1 className="title mt-6">{localizedPage.pageTitle}</h1>
        </div>
      </section>

      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column is-two-thirds">
              {localizedPage.pageContent &&
                renderRichText(localizedPage.pageContent, renderOptions)}
            </div>
          </div>
        </div>
      </section>

      <section className="section mb-6">
        <div className="container">
          {/* Columns */}
          <div className="columns is-multiline">
            {localizedPosts.map(post => {
              const path = `${post.postSlug}-${hashString(post.publicationDate)}`
              return (
                <div className="column is-one-third-desktop is-half-tablet" key={post.contentful_id}>
                  <LocalizedLink to={path}>
                    <div className="card">
                      {post.postHeaderImage && (<div className="card-image">
                        <GatsbyImage
                          objectFit="contain"
                          loading="eager"
                          alt={post.postTitle}
                          image={post.postHeaderImage.gatsbyImageData}
                        />
                      </div>)}
                      <div className="card-content">
                        <h2 className="subtitle">{post.postTitle}</h2>
                        {post.postHeaderContentShort &&
                          renderRichText(post.postHeaderContentShort, renderOptions)}
                        <p><small className="has-text-grey"><FormattedDate value={post.publicationDate} year="numeric" month="long" day="2-digit" /></small></p>
                      </div>
                    </div>
                  </LocalizedLink>
                </div>
              )
            })}
          </div>

          {/* Tiles: Desktop */}
          {/* <div className="is-hidden-tablet-only">
            {localizedPosts
              // split into chunks of 3
              .reduce((acc, cur, index) => {
                const chunkIndex = Math.floor(index / 3)
                const itemIndex = index % 3
                if (!acc[chunkIndex]) acc[chunkIndex] = Array(3).fill(null)
                acc[chunkIndex][itemIndex] = cur
                return acc
              }, [])
              .map((chunk, chunkIndex) => {
                return (
                  <div className="tile is-ancestor" key={chunkIndex}>
                    {chunk.map((post, postIndex) => {
                      // fill up
                      if (post === null) {
                        return (
                          <div className="tile is-parent">
                            <div className="tile"></div>
                          </div>
                        )
                      }
                      const path = `${post.postSlug}-${hashString(post.publicationDate)}`
                      return (
                        <div className="tile is-parent" key={postIndex}>
                          <LocalizedLink to={path} className="tile">
                            <div className="card">
                              {post.postHeaderImage && (<div className="card-image">
                                <GatsbyImage
                                  objectFit="contain"
                                  loading="eager"
                                  alt={post.postTitle}
                                  image={post.postHeaderImage.gatsbyImageData}
                                />
                              </div>)}
                              <div className="card-content">
                                <h2 className="subtitle">{post.postTitle}</h2>
                                {post.postHeaderContentShort &&
                          renderRichText(post.postHeaderContentShort, renderOptions)}
                                <p><small className="has-text-grey"><FormattedDate value={post.publicationDate} year="numeric" month="long" day="2-digit" /></small></p>
                              </div>
                            </div>
                          </LocalizedLink>
                        </div>
                      )
                    })}
                  </div>
                )}
              )}
          </div> */}

          {/* Tiles: Tablet + Mobile */}
          {/* <div className="is-hidden-desktop">
            {localizedPosts
              // split into chunks of 2
              .reduce((acc, cur, index) => {
                const chunkIndex = Math.floor(index / 2)
                const itemIndex = index % 2
                if (!acc[chunkIndex]) acc[chunkIndex] = Array(2).fill(null)
                acc[chunkIndex][itemIndex] = cur
                return acc
              }, [])
              .map((chunk, chunkIndex) => {
                return (
                  <div className="tile is-ancestor" key={chunkIndex}>
                    {chunk.map((post, postIndex) => {
                      // fill up
                      if (post === null) {
                        return (
                          <div className="tile is-parent">
                            <div className="tile"></div>
                          </div>
                        )
                      }
                      const path = `${post.postSlug}-${hashString(post.publicationDate)}`
                      return (
                        <div className="tile is-parent" key={postIndex}>
                          <LocalizedLink to={path} className="tile">
                            <div className="card">
                              {post.postHeaderImage && (<div className="card-image">
                                <GatsbyImage
                                  objectFit="contain"
                                  loading="eager"
                                  alt={post.postTitle}
                                  image={post.postHeaderImage.gatsbyImageData}
                                />
                              </div>)}
                              <div className="card-content">
                                <h2 className="subtitle">{post.postTitle}</h2>
                                {post.postHeaderContentShort &&
                          renderRichText(post.postHeaderContentShort, renderOptions)}
                                <p><small className="has-text-grey"><FormattedDate value={post.publicationDate} year="numeric" month="long" day="2-digit" /></small></p>
                              </div>
                            </div>
                          </LocalizedLink>
                        </div>
                      )
                    })}
                  </div>
                )}
              )}
          </div> */}
        </div>
      </section>

      {/* <pre>{JSON.stringify(pages, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(localizedPosts, null, 2)}</pre> */}
    </Layout>
  )
}

export default BlogPage

export const BlogPageQuery = graphql`
  query ($contentfulId: String!) {
    allContentfulPage(filter: { contentful_id: { eq: $contentfulId } }) {
      nodes {
        node_locale
        pageContent {
          raw
          references {
            ... on ContentfulPage {
              contentful_id
              pageTitle
              pageSlug
              # pageType
            }
            ... on ContentfulPlaceholder {
              contentful_id
              component
            }
          }
        }
        pageTitle
      }
    }
    allContentfulBlogPost(
      sort: {order: DESC, fields: publicationDate}
    ) {
      nodes {
        contentful_id
        node_locale
        postSlug
        postTitle
        publicationDate
        postHeaderImage {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1280
            quality: 80
            breakpoints: [480, 768, 1024, 1216, 1408]
            formats: [AUTO, WEBP]
          )
        }
        postHeaderContent {
          raw
          # references {
          #   ... on ContentfulPlaceholder {
          #     contentful_id
          #     component
          #     videoTitle
          #     videoUrl
          #   }
          # }
        }
        postHeaderContentShort {
          raw
        }
      }
    }
  }
`
